import { Link } from 'gatsby';
import React from 'react';

const FooterContent = ({ blogUrl }: {
  blogUrl: string;
}): JSX.Element => (
  <div className="container">
    <div className="columns">
      <div className="column">
        <p className="is-size-6 has-text-weight-light"><Link className="has-text-light" to="/">Home</Link></p>
        <p className="is-size-6 has-text-weight-light"><Link className="has-text-light" to="/product">Product</Link></p>
        <p className="is-size-6 has-text-weight-light"><a className="has-text-light" href={blogUrl}>Blog</a></p>
        <p className="is-size-6 has-text-weight-light"><Link className="has-text-light" to="/contact">Contact</Link></p>
      </div>
      <div className="column">
        <p className="is-size-6 has-text-weight-light"><Link className="has-text-light" to="/privacy-policy">プライバシーポリシー</Link></p>
        <p className="is-size-6 has-text-weight-light"><Link className="has-text-light" to="/about-website">本サイトについて</Link></p>
      </div>
    </div>
  </div>
);
export default FooterContent;
