import { Link } from 'gatsby';
import React from 'react';

const HeaderContent = ({ blogUrl }: {
  blogUrl: string;
}): JSX.Element => {
  const [isActive, setIsActive] = React.useState(false);
  return (
    <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <p className="is-size-7 has-text-weight-bold">リアキテク・ラボ</p>
        </Link>
        <a
          role="button"
          className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => {
            setIsActive(!isActive);
          }}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>

      <div
        id="navbarBasicExample"
        className={`navbar-menu ${isActive ? 'is-active' : ''}`}
      >
        <div className="navbar-start">
          <Link to="/product" className="navbar-item">
            Product
          </Link>
          <a href={blogUrl} className="navbar-item">
            Blog
          </a>
          <Link to="/contact" className="navbar-item">
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
};
export default HeaderContent;
